import { Grid, Card, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


export default function PlaceholderStoryCard() {
    return (
        <Card style={{margin: "10px", padding: "10px"}} elevation={10}>
            <Grid container spacing={2} justify="center">
                <Grid item sm={12}>
                    <Skeleton variant="text" width="100%" height={40} />
                    <Skeleton variant="text" width="100%" height={40} />
                    <Skeleton variant="text" width="100%" height={40} />
                    <Skeleton variant="text" width="100%" height={40} />
                </Grid>
                <Grid item>
                    <Box sx={{width: "100%", maxHeight: "512px"}}>
                        <Skeleton variant="rect" width="100%" height={60} />
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={2} justify="center" direction={'row'}>
                        <Grid item>
                            <Skeleton variant="rect" width={100} height={35} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" width={100} height={35} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="circle" width={40} height={40} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
