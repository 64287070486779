import * as React from "react";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import MustChoosePlan from "./MustChoosePlan";
import Card from "@material-ui/core/Card";
import {useAuth} from "../util/auth";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    textAlign: "center",
    p: 4,
};

export default function PayMe(props) {
    var text;
    var title;
    const [open] = React.useState(props.open);

    const auth = useAuth()
    const alreadyHasPlan = Boolean(auth.user && auth.user.planName)

    if (alreadyHasPlan) {
        text = <div>
            <p>
                Looks like you're really enjoying your paid plan! Your daily credits will replenish midnight, UTC (London time).
            </p>
            <p>
                If you'd like to get 3 times more daily sections, and also enjoy a better AI model and access to our most advanced features, you can upgrade to our Bard plan.
            </p>
            <p>
                Check out our <Link href={'/pricing'}> pricing </Link> for more information.
            </p>

        </div>
    } else {
        text = (
            <div>
                <p>
                    You'll need to upgrade to a paid plan subscription to continue!
                    Your support is the only way MyTales can run ads-free.
                </p>
                <p>
                    Check out our <Link href={'/pricing'}> pricing </Link> for more information.
                </p>
            </div>
        );


    }

    title = "Continue your story";

    return (
        <Card elevation={10}>
            <Modal
                onClose={props.dismissCallback}
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <MustChoosePlan
                        bgColor="default"
                        size="small"
                        bgImage=""
                        bgImageOpacity={1}
                        title={title}
                        subtitle={text}
                        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
                        buttonText="Upload photo"
                        buttonColor="primary"
                        buttonPath="/pricing"
                    />
                </Box>
            </Modal>
        </Card>
    );
}
