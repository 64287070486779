import React, {useState} from "react";
import {Box, Container, Grid, Typography} from "@material-ui/core/";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {useRouter} from 'next/router'
import {observer} from "mobx-react-lite";
import {apiRequest} from "../util/util";
import sessionInfo from "../util/sessionInfo";
import {useAuth} from "../util/auth";
import StoryCard from "../components/StoryCard";
import Meta from "../components/Meta";
import PlaceHolderStoryCard from "../components/PlaceHolderStoryCard";
import {story, Story} from "../utils/story";
import {StorySection} from "../utils/storySection";
import {UserInputCard} from "../components/userInputCard";
import track from "../util/frontend_analytics";
// do not delete this line or you will get tsx compile error
declare global {
    interface Window {
        analytics: any;
    }
}
const StoryInputView = observer(({story, readOnly}: { story: Story, readOnly: boolean }) => {
    console.log('story id', story.id)
    console.log('story section length', story.sections.length)
    console.log('input readonly', readOnly)

    return <Box mb={12}>
        {(!readOnly || (story.id === undefined) || story.sections.length === 1) && <UserInputCard waitingUser={true}/>}
    </Box>

})

const StoryView = observer(({story, readOnly}: { story: Story, readOnly: boolean }) => {
    // user react memo to only re-render when the story changes
    const finalNotReadOnly = (!readOnly || (story.id === undefined) || story.sections.length === 1)
    const finalReadOnly = !finalNotReadOnly
    console.log('final read only', readOnly)


    const storyRender = <div>
        <Grid>
            <Grid item sm={12}>
                {story.sections.map((section: StorySection, index: number) =>
                    <StoryCard
                        key={index}
                        text={section.text}
                        image={section.image}
                        id={section.id}
                        lastCard={index === story.sections.length - 1}
                        readOnly={finalReadOnly}
                        firstCard={index === 0}
                        endOfStory={section.endOfStory}
                        storyId={story.id}
                        storySection={section}
                        userText={section.userText}
                    />)}
            </Grid>
            <Grid item sm={12}>
                {story.processing && <PlaceHolderStoryCard/>}
            </Grid>
        </Grid>
    </div>

    return (storyRender)
})


function Index({
                   storyId,
                   description,
                   title,
                   firstSection,
                   openerId
               }: {
    storyId?: string,
    description?: string,
    title?: string
    firstSection?: StorySection
    openerId?: string
}) {
    console.log('storyid', story.id)
    const [finalTitle, setFinalTitle] = useState(title)
    const fetchedStoryId = storyId;
    const router = useRouter()
    const auth = useAuth()

    var {theme} = router.query
    if (theme === undefined) {
        theme = "Landing Page Theme"
    }
    var tags;
    var customErotica = false;
    if (theme === "eroticaCustom") {
        tags = ['random', 'straight', 'gay male', 'lesbian', 'college',
            'domination', 'submissive', 'older man', 'older woman', 'taboo', 'cuckold']
        customErotica = true
    }
    sessionInfo.setHome(router.route)

    const [readOnly, setReadOnly] = useState(true)
    // as soon as auth is done, check if the story is owned by the current user
    // if not, set readOnly to true, else set to false
    // make this into an effect predicated on auth
    React.useEffect(() => {

        // if (story.sections.length < 2){
        //     setReadOnly(false)
        //     return
        // }
        if (auth.user !== undefined && auth.user !== false && auth.user !== null) {
            console.log('verified ownership!')
            // @ts-ignore
            apiRequest('get-is-story-owned',
                'POST',
                {
                    userId: auth.user.uid,
                    storyId: storyId
                }).then((res) => {

                // check if code is 200
                debugger
                if (res) {

                    console.log('mineee')
                    setReadOnly(false)

                } else {
                    console.log('not mine')
                    setReadOnly(true)
                }
            })
        }

    }, [auth])


    // when compomnent first mounts, query for a story opener
    // @ts-ignore
    async function genStory() {
        console.log('auth', auth)
        const resJson = await apiRequest('ml', 'POST', {target: 'write', newStory: true, theme: theme})
        track("story_new", {
            theme: theme,
            branch: process.env.BRANCH
        })
        story.setId(resJson.sessionId)
        story.setTheme(theme as string)
        story.setOwned(true)
        setReadOnly(false)
        console.log('storyId is', story.id)
        console.log('story theme is', story.theme)
        const storySection = new StorySection(
            resJson.sectionId,
            resJson.text,
            resJson.imgUrl,
            [resJson.imgUrl],
            resJson.endOfStory,
            resJson.userText
        )
        await story.addSection(storySection)
    }

    async function fetchStory(fetchedStoryId: string) {
        try {
            const res = await apiRequest('ml', 'POST', {target: 'get-story', sessionId: fetchedStoryId})
            story.clear()
            story.setId(fetchedStoryId)
            setFinalTitle(res.title)
            const sections = res.story;
            for (const section of sections) {
                await story.addSection(new StorySection(
                    section.sectionId,
                    section.text,
                    section.imgUrl,
                    [section.imgUrl],
                    section.endOfStory,
                    section.userText
                ))
            }
        } catch (e) {
            console.log('could not fetch story')
            console.log(e)
            genStory()
            router.push('/')
        }
    }

    React.useEffect(() => {
        const handleStoryLifecycle = async () => {
            story.clear()
            if (firstSection) {
                const storySectionObj = StorySection.fromJson(firstSection)
                await story.addSection(storySectionObj)
                story.setTheme("erotica")
                // get storyId from the optional storyId
                console.assert(storyId !== undefined)
                if (storyId !== undefined) {
                    story.setId(storyId)
                }
                const res = await apiRequest('ml', 'POST', {
                    target: 'write',
                    openerId: openerId,
                    theme: 'erotica',
                    openerOnly: true,
                    newStory: true
                });
                console.log('session id is', res.sessionId)
                story.setId(res.sessionId)
                story.setOwned(true)
                setReadOnly(false)
            } else if (fetchedStoryId === undefined) {
                console.log('customErotica', customErotica)
                if (story.sections.length === 0 && !customErotica) {
                    await genStory()
                    console.log('done')
                }
            } else {
                console.log('fetching story id', fetchedStoryId)
                await fetchStory(fetchedStoryId)
            }
        };

        handleStoryLifecycle();

        // Cleanup logic, if necessary, can go here
        return () => {
            // Perform cleanup
        };
    }, []); // Add all dependencies her


    return (
        <Box>
            <Meta description={description}
                  title={finalTitle}
            />
            <Container maxWidth={'sm'}>
                <Typography variant={'h3'}
                            align={'center'}> {finalTitle ? finalTitle : "Start your Adventure"} </Typography>
                {/*<Card variant={'outlined'}>*/}
                {/*    <StoryControl/>*/}
                {/*</Card>*/}
                <StoryView story={story} readOnly={readOnly}/>
                <StoryInputView story={story} readOnly={readOnly}/>
            </Container>
            <div
                style={{display: "none"}}
            >
                <a href={"https://theresanaiforthat.com/ai/mytales/?ref=featured&v=4377"}
                   target={"_blank"}
                   rel={"nofollow"}

                >
                    <img width={"300"} src={"https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"}/>
                </a>
            </div>

        </Box>)
}


export default Index
// export story as a global variable
