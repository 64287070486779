import {makeAutoObservable} from "mobx";
import {apiRequest} from "../util/util";
import {StorySection} from "./storySection";
import track from "../util/frontend_analytics";

export class Story {
    theme: string | undefined
    id: string | undefined
    sections: StorySection[]
    ended: boolean
    processing: boolean
    underEdit: boolean
    owned: boolean

    constructor() {
        this.sections = []
        this.ended = false
        this.processing = false
        this.underEdit = false
        this.owned = false
        makeAutoObservable(this)
    }

    setProcessing(processing: boolean) {
        this.processing = processing
    }

    setUnderEdit(underEdit: boolean) {
        this.underEdit = underEdit
    }

    async addSection(section: StorySection) {

        this.sections.push(section)
        // if the section doesn't have "images" property yet, generate new images
        if (section.images.length === 0) {
            const sessionId = this.id;
            const data = await apiRequest('ml', 'POST', {
                target: 'paint',
                sessionId: sessionId,
                sectionId: section.id
            })

            // make a get request to get the images, result is a json with a list of images
            // check if "src" is in data. If not, assume the data is already a list of images
            var images;

            if (data.lexicaUrl.src) {
                images = [data.lexicaUrl]
            } else {
                const searchUrl = encodeURI(data.lexicaUrl)
                // make a get request on searchURL
                const res = await fetch(searchUrl);
                const resJson = await res.json()
                images = resJson.images
            }


            section.setImages(images)
        }
    }

    setId(id: string) {
        this.id = id
        // put the story id in cookie
        window.sessionStorage.setItem('storyId', id)

    }

    setOwned(owned: boolean) {
        this.owned = owned
    }

    setTheme(theme: string) {
        this.theme = theme;
    }

    deleteLastSection() {

        this.sections.pop()
    }

    setEnded() {
        this.ended = true
    }


    clear() {

        this.sections = []
        this.id = undefined;
        window.sessionStorage.removeItem('storyId')
    }

    async generateNewSection(userText: string,
                             sectionId: string,
                             retry: Boolean = false) {

        const data = await apiRequest('ml', 'POST', {
            target: 'write',
            sessionId: this.id,
            userText: userText,
            sectionId: sectionId,
            retry: retry,
            llama: true,
            modelName:'llama-7b',
            theme: this.theme

        })
        if (data === null) {
            return
        }
        if (data.success === false) {
            const message = data.message;
            alert(message)
            return
        }
        this.setId(data.sessionId)
        await this.addSection(new StorySection(data.sectionId,
            data.text,
            data.imgUrl,
            [data.imgUrl],
            data.endOfStory,
            data.userText
        ))
        if (data.endOfStory) {
            this.setEnded()
        }
        // @ts-ignore
        track("story_continue", {theme: this.theme, branch: process.env.BRANCH})
        return true;
    }

    getLastSection() {
        if (this.sections.length === 0) {
            return null
        }
        return this.sections[this.sections.length - 1]
    }

    getTentativeNextText(): string {
        return "Write the next few sentences here"
    }


}

const story = new Story()
export {story};