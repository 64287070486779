// define props of storyCard
import React, {useState} from "react";
import {Box, Button, Card, CircularProgress, Fade, Grid, TextField, Typography} from "@material-ui/core/";
import {Skeleton} from "@material-ui/lab/";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {apiRequest} from "../util/util";
import {story} from "../utils/story";
import {StorySection} from "../utils/storySection";
import track from "../util/frontend_analytics";


var editText: string;

interface StoryCardProps {
    id: string;
    text: string;
    userText: string | undefined;
    image: string | undefined;
    endOfStory: boolean;
    firstCard: boolean;
    lastCard: boolean
    storyId: string | undefined;
    readOnly: boolean;
    storySection?: StorySection;

}

function StoryCard(props: StoryCardProps) {
    // this is a card that contains the next lines of the story in our text adventure
    // animate the text getting written
    var startingText = '';
    var textcompleted = false;

    if (!props.lastCard || props.readOnly) {
        startingText = props.text;
        textcompleted = true;
    }
    const [textCompleted, setTextCompleted] = useState(true)

    const [text, setText] = React.useState(props.text);
    const [editable, setEditable] = React.useState(false);
    const [editProcessing, setEditProcessing] = React.useState(false);
    console.log('user text ', props.userText)


    // @ts-ignore
    return (
        <div>
            {props.userText && props.userText.length > 0 &&
                <Card key={props.id} style={{margin: "10px", padding: "10px"}} elevation={10}>
                    <Typography variant={'body1'}>
                        <Box sx={{fontStyle: 'italic'}} fontWeight={1000}> {props.userText} </Box></Typography>
                </Card>}
            <Card key={props.id} style={{margin: "10px", padding: "10px"}} elevation={10}>
                <Grid container spacing={2} justify="center">
                    <Grid item sm={12}>
                        {props.endOfStory ? <DisplayCenteredText text={text} editable={editable}/> :
                            <DisplayText text={text} editable={editable}/>}
                    </Grid>

                    {textCompleted && !props.endOfStory && (
                        <Grid item sm={12}>
                            <Fade in timeout={3000}>
                                <Box textAlign={'center'}>
                                    {props.image ? <img style={{width: "100%", objectFit: "cover", maxHeight: "512px"}}
                                                        src={props.image} alt="story image"/> :
                                        <Skeleton variant={'rect'} height={512}/>}
                                </Box>
                            </Fade>
                        </Grid>)}

                    <Grid item sm={12}>
                        {!props.readOnly && props.lastCard && (
                            <Grid container spacing={2} justify="center" direction={'row'}>
                                <Grid item>
                                    {editable ?
                                        <Button
                                            disabled={editProcessing}
                                            onClick={async () => {

                                                setEditable(false);
                                                story.setUnderEdit(false)
                                                setEditProcessing(true)
                                                const origText = props.text;
                                                // we want setEditProcessing to have an immediate effect and not wait for the api request to finish
                                                // so we use a callback function
                                                async function callback() {

                                                    try {

                                                        const data = await apiRequest('ml', 'POST', {
                                                            target: 'edit',
                                                            sessionId: props.storyId,
                                                            sectionId: props.id,
                                                            userText: editText,
                                                            mode: 'edit'
                                                        })
                                                        if (data['success'] === false) {
                                                            const message = data['message'] ?? 'Sorry! Your edit failed for some reason Please try something else'
                                                            alert(message)
                                                            setEditProcessing(false)
                                                            setText(origText);
                                                            return
                                                        }
                                                        setText(editText);
                                                        props.storySection?.updateText(editText)
                                                        setEditProcessing(false)
                                                        console.log(data)
                                                    } catch (e) {
                                                        alert('Sorry! Your edit failed for some reason Please try something else')
                                                        setText(origText);
                                                        props.storySection?.updateText(origText)
                                                        setEditProcessing(false)
                                                    }
                                                }

                                                track('edit_submitted', {})
                                                callback()

                                            }} variant={'contained'} color={"primary"}> Submit Edit</Button>
                                        :
                                        <Button
                                            disabled={editProcessing}
                                            onClick={async () => {
                                                editText = text;
                                                setEditable(true)
                                                story.setUnderEdit(true)
                                                track('edit_started', {})
                                            }} variant={'contained'}> Edit</Button>
                                    }
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={async () => {
                                            try {
                                                const data = await apiRequest('ml', 'POST', {
                                                    target: 'edit',
                                                    sessionId: props.storyId,
                                                    sectionId: props.id,
                                                    mode: 'delete'
                                                })
                                                story.deleteLastSection()
                                                track('section_deleted', {})
                                            } catch (e) {
                                                console.error(e)
                                            }

                                        }}
                                        color={'secondary'}> Delete</Button>
                                </Grid>
                                <Grid item>
                                    {editProcessing && (
                                        <CircularProgress/>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </div>
    );


}

function EditableText(props: { text: string }) {

    return (
        <Box sx={{width: "100%"}}>

            <TextField
                fullWidth={true}
                id="outlined-multiline-static"
                multiline
                defaultValue={props.text}
                onChange={(e) => {
                    editText = e.target.value
                }}
            />
        </Box>
    )
}


interface DisplayTextProps {
    text: string;
    editable: boolean;
}

function DisplayText({text, editable}: DisplayTextProps) {


    const displayTextUneditable =
        <Grid container spacing={1}>
            {text.split('\n').map((item, key) =>
                <Grid item>
                    <Typography key={key} variant="body1"> {item} </Typography>
                </Grid>)}
        </Grid>

    const displayTextEditable =
        <EditableText text={text}/>


    if (editable) {
        console.log('displaying editable')

        return displayTextEditable;
    } else {
        return displayTextUneditable;
    }


}


function DisplayCenteredText({text}: DisplayTextProps) {
    const displayText =
        <Grid container spacing={1} justify="center">
            {text.split('\n').map((item, key) =>
                <Grid item>
                    <Typography key={key} variant="h5"> {item} </Typography>
                </Grid>)}
        </Grid>
    return displayText;
}

export default StoryCard