import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import {useRouter} from "next/router";

function AuthSection(props) {
    // Options by auth type
    const router = useRouter();
    const optionsByType = {
        developer: {
            // Top Title
            title: "Get your API key",
            // Button text
            buttonAction: "Sign up",
            // Footer text and links
            showFooter: true,
            signinText: "Already have an account?",
            signinAction: "Sign in",
            signinPath: "/auth/signin",
            subtitle: (
                <p>
                    Get your API key and <b> 100</b> free API calls by sgining
                    up. No credit card rquired.
                </p>
            ),
            // Terms and privacy policy agreement
            showAgreement: true,
            termsPath: "/legal/terms-of-service",
            privacyPolicyPath: "/legal/privacy-policy",
        },
        signup_required: {
            // Top Title
            title: "Sign up required",
            // Button text
            buttonAction: "Sign up",
            // Footer text and links
            subtitle: "Continue for free by signing up.",
            showFooter: true,
            signinText: "Already have an account?",
            signinAction: "Sign in",
            signinPath: "/auth/signin",
            // Terms and privacy policy agreement
            showAgreement: true,
            termsPath: "/legal/terms-of-service",
            privacyPolicyPath: "/legal/privacy-policy",
        },

        signup: {
            // Top Title
            title: "Sign up",
            // Button text
            buttonAction: "Sign up",
            // Footer text and links
            showFooter: true,
            signinText: "Already have an account?",
            signinAction: "Sign in",
            signinPath: "/auth/signin",
            // Terms and privacy policy agreement
            showAgreement: true,
            termsPath: "/legal/terms-of-service",
            privacyPolicyPath: "/legal/privacy-policy",
        },
        signin: {
            title: "Welcome back",
            buttonAction: "Sign in",
            showFooter: true,
            signupAction: "Create an account",
            signupPath: "/auth/signup",
            forgotPassAction: "Forgot Password?",
            forgotPassPath: "/auth/forgotpass",
        },
        forgotpass: {
            title: "Get a new password",
            buttonAction: "Reset password",
            showFooter: true,
            signinText: "Remember it after all?",
            signinAction: "Sign in",
            signinPath: "/auth/signin",
        },
        changepass: {
            title: "Choose a new password",
            buttonAction: "Change password",
        },
    };

    // Ensure we have a valid auth type
    var type = optionsByType[props.type] ? props.type : "signup";
    // Get options object for current auth type
    var options = optionsByType[type];
    if (router.query.developer) {
        options = optionsByType["developer"];
    }

    if (props.type === 'signup_required') {
        type = 'signup'
    }
    console.log('after auth path...')
    console.log(props.afterAuthPath)


    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container maxWidth="xs">
                <SectionHeader
                    title={options.title}
                    subtitle={options.subtitle}
                    size={4}
                    textAlign="center"
                />
                <Auth
                    type={type}
                    buttonAction={options.buttonAction}
                    providers={props.providers}
                    afterAuthPath={props.afterAuthPath}
                    key={type}
                />

                {options.showFooter && <AuthFooter type={type} {...options} />}
            </Container>
        </Section>
    );
}

export default AuthSection;
