import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Link from "next/link";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: `0 ${theme.spacing(3)}px`,
    },
    image: {
        margin: "0 auto",
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
    sectionHeader: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    }
}));

function MustChoosePlan(props) {
    const classes = useStyles();

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container className={classes.container}>
                <Grid container alignItems="center" spacing={6}>
                    <Grid container item direction="column" xs={12} md={6}>
                        <Box textAlign={{ xs: "center", md: "left" }}>
                            <SectionHeader
                                title={props.title}
                                subtitle={props.subtitle}
                                size={4}
                                className={classes.sectionHeader}
                            />
                            <Link href="/pricing" passHref>
                                <Button
                                    id="choose_plan_button"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    component="a"
                                    className={classes.button}
                                >
                                    See Plans
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md>
                        <img
                            src="https://res.cloudinary.com/dy4s1umzd/image/upload/c_crop,w_720/v1698164766/photobear_site_assets/c4f52f57-a3bf-44dc-bc32-b1ad7aaf1beb.webp"
                            alt="illustration"
                            className={classes.image}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Section>
    );
}

export default MustChoosePlan;
