import {Modal} from "@material-ui/core";
import AuthSection from "./AuthSection";
import React from "react";

interface SignupPopupProps {
    signupModal: any
    setSignupModal: any
}

export function SignupPopup({signupModal, setSignupModal}: SignupPopupProps) {
    return <Modal open={signupModal} onClose={() => setSignupModal(false)}>

        <AuthSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            type={'signup_required'}
            providers={["google"]}
            afterAuthPath={() => {
                setSignupModal(false)
            }}
        />
    </Modal>;
}