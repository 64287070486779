import React, {useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Button, Card, Grid, Modal, TextField, Typography} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import {makeStyles} from "@material-ui/core/styles";
import track from "../util/frontend_analytics";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    customTextField: {
        '& .MuiInputBase-input': {
            cursor: 'pointer',
        },
    },
    card: {
        padding: theme.spacing(2),
        maxWidth: '80%',
        outline: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
    },
    copiedTextField: {
        borderColor: theme.palette.success.main,
    },
}));

export default function ShareButton({link}: { link?: string }) {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    const copiedToast = () => (
        <Snackbar open={copied} autoHideDuration={6000} onClose={() => setCopied(false)}>
            <Alert onClose={() => setCopied(false)} severity="success">
                Link copied to clipboard!
            </Alert>
        </Snackbar>
    );

    const copyToClipboard = async () => {
        console.log('copying to clipboard')
        console.log(link)
        if (link) {
            await navigator.clipboard.writeText(link);
            setCopied(true);
            track("copy_story_link", {value: 0.05});
        }
    };

    return (
        <div>
            <Button
                startIcon={<ShareIcon/>}
                onClick={() => {
                    setOpen(true);
                }}
            >
                Share
            </Button>
            <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
                <Card className={classes.card} elevation={10}>
                    <Typography variant="h6">Share the link below for others to read your tale!</Typography>
                    <Typography variant="body1" color="textSecondary">
                        Only people with this link can access your story. Do not share it if you'd rather keep it
                        private.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.customTextField}
                                fullWidth
                                multiline
                                value={link}
                                InputProps={{
                                    readOnly: true,
                                }}
                                onClick={copyToClipboard}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Modal>
            {copiedToast()}
        </div>
    );
}