import {makeAutoObservable} from "mobx";

export class StorySection {
    id: string;
    text: string;
    image: string | undefined;
    userText: string | undefined;
    endOfStory: boolean;
    images: any[];

    constructor(id: string, text: string, image: string | undefined, images: any[] = [], endOfStory: boolean, userText: string | undefined = undefined) {
        this.id = id;
        this.text = text;
        this.image = image
        this.images = images;
        this.endOfStory = endOfStory
        this.userText = userText
        makeAutoObservable(this)
    }

    updateText(text: string) {
        this.text = text;
    }

    updateImage(image: string) {
        this.image = image;
    }

    setImages(images: any[]) {
        this.images = images;
        this.image = images[0].src;
    }

    toJSON() {
        return {
            id: this.id,
            text: this.text,
            image: this.image,
            images: this.images,
            endOfStory: this.endOfStory,
        };
    }

    static fromJson(json: any): StorySection {
        const {id, text, image, images, endOfStory} = json;
        return new StorySection(id, text, image, images, endOfStory);
    }


}